import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Versioning`}</h1>
    <p>{`How the version numbers are incremented in an NDS package.`}</p>
    <h2>{`Semantic Versioning`}</h2>
    <p>{`All NDS packages follow `}<a parentName="p" {...{
        "href": "https://semver.org/"
      }}>{`Semantic Versioning`}</a>{` (SemVer), which allows for three types of changes:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`PATCH`}</inlineCode>{` changes for backwards compatible bug fixes,`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MINOR`}</inlineCode>{` changes for new functionality added in a backwards compatible manner, and`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MAJOR`}</inlineCode>{` changes for incompatible API changes`}</li>
    </ul>
    <p>{`These changes are represented in the version number like `}<inlineCode parentName="p">{`MAJOR.MINOR.PATCH`}</inlineCode>{`. For version `}<inlineCode parentName="p">{`3.2.4`}</inlineCode>{`, that would mean there has been 4 fixes and 2 new features since the last breaking change.`}</p>
    <h2>{`Major Changes`}</h2>
    <p>{`Major version updates are for breaking changes, and may require updates to applications already consuming the package. For example:`}</p>
    <ul>
      <li parentName="ul">{`Removing a prop`}</li>
      <li parentName="ul">{`Renaming a prop`}</li>
      <li parentName="ul">{`Changing the way an existing prop works`}</li>
      <li parentName="ul">{`Changing the name of a component`}</li>
      <li parentName="ul">{`Removing a component`}</li>
      <li parentName="ul">{`Visual updates that could affect the layout of a page, like box-sizing, width, height`}</li>
      <li parentName="ul">{`Changes to fonts that could cause wrapping changes, e.g font-size, font-weight, or letter-spacing`}</li>
    </ul>
    <h2>{`Minor Changes`}</h2>
    <p>{`Minor changes are additive changes with backwards compatiblity. For example:`}</p>
    <ul>
      <li parentName="ul">{`Adding a new component or feature`}</li>
      <li parentName="ul">{`Adding new, optional props to an existing component`}</li>
      <li parentName="ul">{`Deprecating something and adding a replacement`}</li>
      <li parentName="ul">{`Visual updates to aesthetic features like colour and shadows`}</li>
      <li parentName="ul">{`Visual updates to layered components like tooltips that won't affect the layout of a page`}</li>
    </ul>
    <h2>{`Patch Changes`}</h2>
    <p>{`Patch changes are for fixing defects and optimizing existing code. For example:`}</p>
    <ul>
      <li parentName="ul">{`Fixing a defect`}</li>
      <li parentName="ul">{`Adding or updating tests`}</li>
      <li parentName="ul">{`Updating or refactoring existing code in a way that doesn't alter the API`}</li>
    </ul>
    <p><em parentName="p">{`Thank you to `}<a parentName="em" {...{
          "href": "https://designsystem.morningstar.com/getting-started/versioning-and-breaking-changes"
        }}>{`Morningstar`}</a>{` for the inspiration for this page.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      